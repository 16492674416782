<template>
  <button
    class="vButton"
    :type="type"
    :disabled="disabled"
    :class="{
      fixWidth: fixWidth,
      'vButton--gray': theme === 'gray',
      'vButton--white': theme === 'white',
    }"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    type: {
      type: String,
      require: false,
      default: 'button',
    },
    theme: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    fixWidth: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  emits: ['handleClick'],
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>

<style lang="scss">
.vButton {
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
  background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  svg {
    margin-left: 12px;
    min-width: 24px;
  }
  &.fixWidth {
    max-width: 313px;
  }
  &:disabled {
    background: #d5dbf1;
    cursor: not-allowed;
  }
  &--gray {
    color: #343d5d;
    background: #edeef1;
  }
  &--white {
    color: #80869a;
    background: #fff;
    border: 1px solid #e6e7eb;
  }
}
</style>
