<template>
  <div class="sign">
    <div class="sign__container">
      <h2 class="sign__title">
        Зарегистрироваться в <br>
        Tapper
      </h2>
      <p class="sign__text sign__text--center sign__text--mb">
        Уже есть аккаунт?
        <router-link
          to="/users"
          class="sign__text-link"
        >
          Войти
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.33325 0.666641L10.6666 3.99998M10.6666 3.99998H1.33325H10.6666ZM7.33325 7.33331L10.6666 3.99998L7.33325 7.33331Z"
              stroke="#6764FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </p>
      <Form
        v-slot="{ errors }"
        class="sign__form"
        @submit="checkForm"
      >
        <!-- Имя -->
        <vInput
          id="name"
          v-model="name"
          :value="name"
          placeholder="Ваше имя"
          :required="true"
          rule="required|rus"
        />

        <!-- Телефон -->
        <vInput
          id="phone"
          v-model="phone"
          :value="phone"
          placeholder="Телефон"
          :required="true"
          rule="phone"
        />

        <!-- E-mail -->
        <vInput
          id="email"
          v-model="email"
          :value="email"
          placeholder="E-mail"
          :required="true"
          rule="mail|max:254"
          :error="USER_ERROR"
        />

        <!-- Пароль -->
        <vInput
          id="password"
          v-model.trim="password"
          type="password"
          :value="password"
          placeholder="Пароль"
          :required="true"
          rule="min:6"
        />

        <!-- Подтверждение пароля -->
        <vInput
          id="confirmation"
          v-model.trim="confirmPassword"
          type="password"
          :value="confirmPassword"
          placeholder="Подтверждение пароля"
          :required="true"
          rules="confirmed:@password"
        />

        <!-- Название заведения -->
        <vInput
          id="name_shop"
          v-model="name_shop"
          :value="name_shop"
          placeholder="Название заведения"
          :required="true"
          rules="name_shop"
          :error="SHOP_ERROR"
        />

        <!-- Recaptcha -->
        <vue-recaptcha
          v-show="showRecaptcha"
          ref="vueRecaptcha"
          site-key="6Ldqy-cUAAAAAPUTV7PRmVrVjhcW1g2VmHUhguaF"
          size="normal"
          theme="light"
          hl="ru"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
        />
        <br>

        <p
          v-if="!politic"
          class="vLandingFeedback__error-text"
          style="
            font-size: 12px;
            line-height: 16px;
            color: #ec4e4e;
            margin-bottom: 15px;
          "
        >
          Для отправки формы согласитесь с условиями Пользовательского
          соглашения и Политикой обработки персональных данных
        </p>

        <vButton
          type="submit"
          :disabled="
            Object.keys(errors).length > 0 ||
              name.length < 1 ||
              phone.length < 1 ||
              email.length < 1 ||
              name_shop.length < 1 ||
              password.length < 1 ||
              confirmPassword.length < 1 ||
              !politic ||
              !recaptchaVerifiedSuccess
          "
        >
          Зарегистрироваться
        </vButton>
        <label
          class="sign__conditions"
        ><input
           v-model="politic"
           type="checkbox"
           checked="checked"
           class="sign__conditions-check"
         >
          <div class="sign__conditions-text">
            <p>
              Согласен с условиями
              <a
                href="#conf"
                class="sign__conditions-text-link"
                @click="togglePoliticModal"
              >Пользовательского соглашения</a>
              и

              <a
                href="#conf"
                class="sign__conditions-text-link"
                @click="togglePoliticModal"
              >Политикой обработки персональных данных</a>
            </p>
          </div></label>
      </Form>
      <a href="/">
        <img
          src="~@/assets/images/logo.svg"
          class="sign__logo"
          alt="logo"
        >
      </a>
      <p class="sign__copyright">
        &copy; powered by tapper 2021
      </p>
    </div>

    <!-- Modal Registration -->
    <vModalSuccess
      v-if="GET_REGISTRATION_SUCCESS"
      theme="light"
      :close-bth="false"
      @closeModal="closeModalRegistrationSuccess"
    >
      <template #img>
        <img
          src="~@/assets/images/reg_complete_img.png"
          alt=""
        >
      </template>
      <template #title>
        Вы успешно зарегистрированы!
      </template>
      <template #description>
        Через несколько секунд вы будете перенаправлены в Личный кабинет
      </template>
    </vModalSuccess>
    <vLandingPoliticModal
      v-show="GET_MODAL_POLITIC"
      @togglePoliticModal="togglePoliticModal"
    />
  </div>
</template>

<script>
import { MODE } from '@/constants'
import vueRecaptcha from 'vue3-recaptcha2'
import { Form } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import vModalSuccess from '@/components/modals/v-modal-success'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import vLandingPoliticModal from '@/components/landing/v-landing-politic-modal'
export default {
  name: 'RegistrationPage',
  components: {
    vInput,
    vButton,
    Form,
    vModalSuccess,
    vueRecaptcha,
    vLandingPoliticModal,
  },
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      name_shop: '',
      password: '',
      confirmPassword: '',
      politic: true,
      showRecaptcha: true,
      politicModal: false,
      recaptchaVerifiedSuccess: false,
    }
  },
  computed: {
    ...mapGetters('user', ['USER_ERROR', 'GET_REGISTRATION_SUCCESS']),
    ...mapGetters('shops', ['SHOP_ERROR']),
    ...mapGetters('modalPolitic', ['GET_MODAL_POLITIC']),
  },
  watch: {
    email: function (val) {
      this.ACTION_CLEAR_USER_ERRORS()
    },
  },
  mounted() {
    if (MODE === 'STAGING' || MODE === 'DEV') {
      ;(this.showRecaptcha = false), (this.recaptchaVerifiedSuccess = true)
    }
    this.TOGGLE_PRELOADER(false)
  },
  methods: {
    ...mapActions('user', [
      'CHECK_EMAIL',
      'SIGNUP',
      'ACTION_CLEAR_USER_ERRORS',
    ]),
    ...mapActions('shops', ['CHECK_SHOPS', 'CLOSE_REGISTRATION_ACCESS']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    togglePoliticModal() {
      this.CHANGE_MODAL_POLITIC()
    },
    async checkForm() {
      await this.CHECK_EMAIL(this.email)
      // await this.CHECK_SHOPS(this.name_shop)
      if (
        this.USER_ERROR.length < 1 &&
        this.SHOP_ERROR.length < 1 &&
        this.politic
      ) {
        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
          phone: convertPhoneFormat(this.phone),
          name_shop: this.name_shop,
        }
        await this.SIGNUP(data)
        if (this.GET_REGISTRATION_SUCCESS) {
          setTimeout(() => {
            this.CLOSE_REGISTRATION_ACCESS()
            this.$router.push('/profile')
          }, 2000)
        }
      }
    },
    recaptchaVerified(response) {
      this.recaptchaVerifiedSuccess = true
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.sign__title {
  text-align: center;
  margin-bottom: 24px;
}
</style>
